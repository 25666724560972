import { gql } from "@apollo/client";

export const GET_MERCHANT_MEMBERS = gql`
query GetMerchantMembers($id: uuid!) {
  client_profile_user_role(where: {client_profile_id: {_eq: $id}}) {
    role
    email
  }
}
`;

export const GET_MERCHANT_AVERAGE_RATING = gql`
query GetMerchantRating($ids: [uuid!]) {
  merchant_ratings_stats (where: {client_profile_id: {_in: $ids}}) {
    average_rating
    client_profile_id
  }
}
`;

export const GET_MERCHANT_RATING_FULL_INFORMATION = gql`
query GetMerchantRatingFullInformation($id: uuid!) {
  client_profile(where: {id: {_eq: $id}}) {
    id
    company_name
    logo_file {
      url
    }
  }
  merchant_ratings_stats(where: {client_profile_id: {_eq: $id}}) {
    client_profile_id
    average_rating
    total_ratings
    approval_timeliness
    creative_freedom
    clarity_of_brief
    professionalism_and_responsiveness
  }
  merchant_ratings(where: {client_profile_id: {_eq: $id}}) {
    clarity_of_brief
    creative_freedom
    approval_timeliness
    professionalism_and_responsiveness
    comment
    kol_profile {
      first_name
      last_name
    }
    campaign {
      name
    }  

  }    
}
`;