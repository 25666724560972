import React, { useEffect, useState } from 'react';
import './WorkItemForm.css';
import { CampaignKol, clearKolWorkItem, ContentType, getTierFromFanbase, getWorkItemType, WorkItemData } from './Models';
import ContentMetricsForm from './ContentMetricsForm';
import WorkItemForm from './WorkItemForm';
import HeaderForm from './HeaderForm';
import { se } from 'date-fns/locale';

class WorkItemsProps {
    campaignKol: CampaignKol | undefined;
    onDiscard: (() => void) | undefined;
    onSave: ((campaignKol: CampaignKol) => void) | undefined;
}

export const WorkItemsForm: React.FC<WorkItemsProps> = ({ campaignKol, onDiscard, onSave }) => {
    //const [workItems, setWorkItems] = useState<WorkItemData[]>(campaignKol?.workItems ?? []);

    const [updatedCampaingKol, setUpdatedCampaignKol] = useState<CampaignKol | undefined>(campaignKol);

    useEffect(() => {
        if (campaignKol) {
            setUpdatedCampaignKol(campaignKol);
        }
    }, [campaignKol]);

    const handleFanbaseChange = (kolProfileId: string, fanbase: number) => {
        const newWorkItems = [...updatedCampaingKol?.workItems ?? []];
        for (let i = 0; i < newWorkItems.length; i++) {
            if (newWorkItems[i].kol?.kol_profile_id === kolProfileId) {
                newWorkItems[i].kol!.fanbase = fanbase;
            }
        }
        const newCampaignKol = { ...campaignKol, workItems: newWorkItems } as CampaignKol;
        setUpdatedCampaignKol(newCampaignKol);
    }

    const handleWorkItemChange = (data: WorkItemData) => {
        const newWorkItems = [...updatedCampaingKol?.workItems ?? []];
        let index = -1;
        for (let i = 0; i < newWorkItems.length; i++) {
            if (newWorkItems[i].kolInsight?.metricId === data.kolInsight?.metricId) {
                index = i;
                break;
            }
        }
        if (index === -1) {
            return;
        }
        newWorkItems[index] = data;
        const newCampaignKol = { ...campaignKol, workItems: newWorkItems } as CampaignKol;
        setUpdatedCampaignKol(newCampaignKol);
    };
    const socialPlatform = campaignKol?.socialPlatform ?? '';
    const workItems = campaignKol?.workItems ?? [];
    if (workItems.length === 0) {
        return <div></div>;
    }
    const kolPlatforms = (workItems[0].kol?.kol_platforms ?? []).filter((item) => item.platform_handle === socialPlatform);;
    let kolHandle = '';
    if (kolPlatforms.length > 0) {
        kolHandle = kolPlatforms[0].username ?? '';
    }
    return (
        <div>
            <HeaderForm
                title="Insights Report Details"
                onDiscard={() => {
                    if (onDiscard) {
                        clearKolWorkItem();
                        onDiscard();
                    }
                }}
                onSave={() => { if (onSave && updatedCampaingKol) onSave(updatedCampaingKol); }} />

            {campaignKol && campaignKol.workItems && campaignKol.workItems.length > 0 && (
                <ContentMetricsForm
                    title="KOL Details"
                    kol={kolHandle}
                    fanbase={campaignKol.workItems[0].kol?.fanbase ?? 0}
                    tier={getTierFromFanbase(campaignKol.workItems[0].kol?.fanbase ?? 0)}
                    onFanbaseChange={(kolProfileId: string, fanbase: number) => {
                        handleFanbaseChange(kolProfileId, fanbase);
                    }}
                    kolProfileId={campaignKol.workItems[0].kol?.kol_profile_id ?? ''}
                />
            )}
            {(updatedCampaingKol?.workItems ?? []).map((item, index) => (
                <WorkItemForm key={index} data={item} onDataChange={(data) => handleWorkItemChange(data)} />
            ))}
        </div>
    );
}