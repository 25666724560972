import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Rating, LinearProgress } from "@mui/material"
import styled from "styled-components"
import React, { useEffect, useState } from "react";
import { DefaultOptions, useApolloClient } from "@apollo/client"
import { GET_MERCHANT_RATING_FULL_INFORMATION } from "../../queries/merchant"
import { GET_KOL_RATING_FULL_INFORMATION } from "../../queries/kol-profile"

import { KolRatings, MerchantDetails, MerchantRatings } from "./Models";
import ic_rating_star from '../../assets/ic_rating_star.png';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);


interface MerchantKolRatingProps {
    id: string;
    type: string;
}

export const MerchantKolRating: React.FC<MerchantKolRatingProps> = ({
    id, type
}) => {

    const client = useApolloClient()
    const [merchantDetails, setMerchantDetails] = useState<MerchantDetails | null>(null);

    useEffect(() => {
        const defaultOptions: DefaultOptions = {
            watchQuery: {
                fetchPolicy: "no-cache",
                errorPolicy: "ignore",
            },
            query: {
                fetchPolicy: "no-cache",
                errorPolicy: "all",
            },
        }
        client.defaultOptions = defaultOptions

        if (type === 'merchant') {
            fetchMerchantFullInformation()
        } else if (type === 'kol') {
            fetchKolFullInformation(id)
        }
    }, [])

    const fetchMerchantFullInformation = async () => {
        let merchantInformation = await client.query({
            query: GET_MERCHANT_RATING_FULL_INFORMATION,
            variables: {
                id: id,
            },
        })

        let rating = 0
        if (merchantInformation.data.merchant_ratings_stats.length > 0) {
            rating = merchantInformation.data.merchant_ratings_stats[0].average_rating
        }
        rating = Math.round(rating * 10) / 10

        let average_clarify = 0
        let average_freedom = 0
        let average_approval = 0
        let average_professionalism = 0

        if (merchantInformation.data.merchant_ratings_stats.length > 0) {
            average_clarify = roundNumber(merchantInformation.data.merchant_ratings_stats[0].creative_freedom / merchantInformation.data.merchant_ratings_stats[0].total_ratings)
            average_freedom = roundNumber(merchantInformation.data.merchant_ratings_stats[0].creative_freedom / merchantInformation.data.merchant_ratings_stats[0].total_ratings)
            average_approval = roundNumber(merchantInformation.data.merchant_ratings_stats[0].approval_timeliness / merchantInformation.data.merchant_ratings_stats[0].total_ratings)
            average_professionalism = roundNumber(merchantInformation.data.merchant_ratings_stats[0].professionalism_and_responsiveness / merchantInformation.data.merchant_ratings_stats[0].total_ratings)
        }

        const merchantDetails = {
            name: merchantInformation.data.client_profile[0].company_name,
            average_rating: rating,
            profileImageUrl: merchantInformation.data.client_profile[0].logo_file?.url ?? undefined,
            average_clarify: average_clarify,
            average_freedom: average_freedom,
            average_approval: average_approval,
            average_professionalism: average_professionalism,
            merchant_ratings: [],
            kol_ratings: [],
            average_content_quality: 0,
            average_punctuality: 0,
            average_content_performance: 0
        }

        let merchantRatings = merchantInformation.data.merchant_ratings.map((rating: any) => {
            return {
                campaign: rating.campaign.name,
                kol_name: `${rating.kol_profile.first_name} ${rating.kol_profile.last_name}`,
                clarify: rating.clarity_of_brief,
                freedom: rating.creative_freedom,
                approval: rating.approval_timeliness,
                professionalism: rating.professionalism_and_responsiveness,
                overall: roundNumber((rating.clarity_of_brief + rating.creative_freedom + rating.approval_timeliness + rating.professionalism_and_responsiveness) / 4),
                comment: rating.comment
            } as MerchantRatings
        })
        merchantDetails.merchant_ratings = merchantRatings
        setMerchantDetails(merchantDetails)
    }

    const fetchKolFullInformation = async (kolId: string) => {
        let kolInformation = await client.query({
            query: GET_KOL_RATING_FULL_INFORMATION,
            variables: {
                id: kolId,
            },
        })
        let rating = 0
        if (kolInformation.data.kol_ratings_stats.length > 0) {
            rating = kolInformation.data.kol_ratings_stats[0].average_rating
        }
        rating = Math.round(rating * 10) / 10
        let average_content_performance = 0
        let average_content_quality = 0
        let average_punctuality = 0
        let average_professionalism = 0

        if (kolInformation.data.kol_ratings_stats.length > 0) {
            average_content_performance = roundNumber(kolInformation.data.kol_ratings_stats[0].content_performance / kolInformation.data.kol_ratings_stats[0].total_ratings)
            average_content_quality = roundNumber(kolInformation.data.kol_ratings_stats[0].content_quality_and_creativity / kolInformation.data.kol_ratings_stats[0].total_ratings)
            average_punctuality = roundNumber(kolInformation.data.kol_ratings_stats[0].punctuality / kolInformation.data.kol_ratings_stats[0].total_ratings)
            average_professionalism = roundNumber(kolInformation.data.kol_ratings_stats[0].professionalism_and_responsiveness / kolInformation.data.kol_ratings_stats[0].total_ratings)
        }

        let profileImageUrl = ''
        if (kolInformation.data.kol_profile[0].kol_file_metadata.length > 0) {
            profileImageUrl = kolInformation.data.kol_profile[0].kol_file_metadata[0].file_metadata.url
        }

        const merchantDetails = {
            name: kolInformation.data.kol_profile[0].first_name + ' ' + kolInformation.data.kol_profile[0].last_name,
            average_rating: rating,
            profileImageUrl: profileImageUrl,
            average_clarify: 0,
            average_freedom: 0,
            average_approval: 0,
            average_professionalism: average_professionalism,
            merchant_ratings: [],
            kol_ratings: [],
            average_content_quality: average_content_quality,
            average_punctuality: average_punctuality,
            average_content_performance: average_content_performance
        }

        let kolRatings = kolInformation.data.kol_ratings.map((rating: any) => {
            return {
                campaign: rating.campaign.name,
                merchant_name: rating.client_profile.company_name,
                content_quality: rating.content_quality_and_creativity,
                punctuality: rating.punctuality,
                content_performance: rating.content_performance,
                professionalism: rating.professionalism_and_responsiveness,
                overall: roundNumber((rating.content_quality_and_creativity + rating.punctuality + rating.professionalism_and_responsiveness + rating.content_performance) / 4),
                comment: rating.comment
            } as KolRatings
        })
        merchantDetails.kol_ratings = kolRatings

        setMerchantDetails(merchantDetails)
    }

    const MerchantAvatar = (url: string | undefined) => {
        if (url) {
            return <img src={url} alt="Profile" className="profile-image" />
        } else {
            return <img src="https://placehold.co/80x80" alt="Profile" className="profile-image" />
        }
    }

    const SeperateInsight = (title: string, value: number) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <div style={{ color: '#79767D', width: '200px', fontSize: '12px' }}>{title}</div>
                <div style={{ marginLeft: '10px' }}>
                    <LinearProgress style={{ height: '10px', width: '200px', borderRadius: '10px' }} variant="determinate" value={20 * value} />
                </div>
                <div style={{ color: 'black', fontSize: '12px', fontWeight: 'bold', width: '25px', marginLeft: '10px' }}>{value}</div>
                <img src={ic_rating_star} width={15} height={15} />
            </div>
        )
    }

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
    };

    const roundNumber = (num: number) => {
        return Math.round(num * 10) / 10
    }

    return (
        <TabContentWrapper>
            <div style={{ fontSize: '16px' }}><b>Average Rating</b></div>
            <HorizontalLayout style={{ marginTop: '16px' }}>
                {MerchantAvatar(merchantDetails?.profileImageUrl)}
                <div className="text-container">
                    <div className="top-text">{merchantDetails?.name}</div>
                    <div className="bottom-text">
                        <Rating color="#2383F3" name="half-rating-read" value={merchantDetails?.average_rating ?? 0} defaultValue={5} precision={0.5} readOnly />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="rating-text">{merchantDetails?.average_rating}</div>
                            <div style={{ color: '#79767D', fontSize: '13px', marginLeft: '10px' }}>/</div>
                            <div style={{ color: '#79767D', fontSize: '13px', marginLeft: '10px' }}>5</div>
                        </div>
                    </div>
                </div>
            </HorizontalLayout>
            <div style={{ fontSize: '16px', marginTop: '20px' }}><b>By Category</b></div>
            {type === 'merchant' && (
                <GraphContainer>
                    <div className="seperate_insight">
                        {SeperateInsight('Clarity of Brief', merchantDetails?.average_clarify ?? 0)}
                        {SeperateInsight('Creative Freedom', merchantDetails?.average_freedom ?? 0)}
                        {SeperateInsight('Approval Timeliness', merchantDetails?.average_approval ?? 0)}
                        {SeperateInsight('Professionalism & Responsiveness', merchantDetails?.average_professionalism ?? 0)}
                    </div>
                    <div className="radiant_graph">
                        <Radar data={{
                            labels: ['Clarity of Brief', 'Creative Freedom', 'Approval Timeliness', 'Professionalism & Responsiveness'],
                            datasets: [
                                {
                                    label: 'Ratings',
                                    data: [merchantDetails?.average_clarify ?? 0, merchantDetails?.average_freedom ?? 0, merchantDetails?.average_approval ?? 0, merchantDetails?.average_professionalism],
                                    backgroundColor: 'rgba(34, 202, 236, 0)',
                                    borderColor: '#2383F3',
                                    borderWidth: 2,

                                },

                            ],
                        }} options={options} />

                    </div>

                </GraphContainer>
            )}
            {type === 'kol' && (
                <GraphContainer>
                    <div className="seperate_insight">
                        {SeperateInsight('Content Quality & Creativity', merchantDetails?.average_content_quality ?? 0)}
                        {SeperateInsight('Punctuality', merchantDetails?.average_punctuality ?? 0)}
                        {SeperateInsight('Content Performance', merchantDetails?.average_content_performance ?? 0)}
                        {SeperateInsight('Professionalism & Responsiveness', merchantDetails?.average_professionalism ?? 0)}
                    </div>
                    <div className="radiant_graph">
                        <Radar data={{
                            labels: ['Content Quality & Creativity', 'Punctuality', 'Content Performance', 'Professionalism & Responsiveness'],
                            datasets: [
                                {
                                    label: 'Ratings',
                                    data: [merchantDetails?.average_content_quality ?? 0, merchantDetails?.average_punctuality ?? 0, merchantDetails?.average_content_performance ?? 0, merchantDetails?.average_professionalism],
                                    backgroundColor: 'rgba(34, 202, 236, 0)',
                                    borderColor: '#2383F3',
                                    borderWidth: 2,

                                },

                            ],
                        }} options={options} />

                    </div>

                </GraphContainer>
            )}
            <div style={{ fontSize: '16px', marginTop: '20px', marginBottom: '20px' }}><b>Reviews</b></div>
            <TableContainer component={Paper}>
                {type === 'merchant' && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Campaign</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>KOL Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Clarity of Brief</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Creative Freedom</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Approval Timeliness</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Professionalism <br />Responsiveness</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Overall</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {merchantDetails?.merchant_ratings.map((rating, index) => (
                                <TableRow key={index}>
                                    <TableCell>{rating.campaign}</TableCell>
                                    <TableCell>{rating.kol_name}</TableCell>
                                    <TableCell>{rating.clarify}</TableCell>
                                    <TableCell>{rating.freedom}</TableCell>
                                    <TableCell>{rating.approval}</TableCell>
                                    <TableCell>{rating.professionalism}</TableCell>
                                    <TableCell>{rating.overall}</TableCell>
                                    <TableCell>{rating.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                {type === 'kol' && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Campaign</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Merchant</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Content Quality <br />Creativity</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Punctuality</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Content<br />Performance</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Professionalism <br />Responsiveness</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Avg. Rating</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {merchantDetails?.kol_ratings.map((rating, index) => (
                                <TableRow key={index}>
                                    <TableCell>{rating.campaign}</TableCell>
                                    <TableCell>{rating.merchant_name}</TableCell>
                                    <TableCell>{rating.content_quality}</TableCell>
                                    <TableCell>{rating.punctuality}</TableCell>
                                    <TableCell>{rating.content_performance}</TableCell>
                                    <TableCell>{rating.professionalism}</TableCell>
                                    <TableCell>{rating.overall}</TableCell>
                                    <TableCell>{rating.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>

        </TabContentWrapper>
    )
}

const TabContentWrapper = styled.div`
  
`

const HorizontalLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .profile-image {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 65px;
  }

  .top-text {
    align-self: flex-start;
    font-size: 0.8rem;
  }

  .bottom-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .rating-bar {
    font-size: 1.2rem;
  }

  .rating-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1d1b20;
  }
`
const GraphContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  .seperate_insight {
    width: 40%;
    display: column;
    margin-right: 20px;
    
  }

  .radiant_graph {
    flex: 1;
    width: 200px;
    height: 200px;

  }
`
