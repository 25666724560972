import { gql } from "@apollo/client";

export const ENABLE_DISABLE_KOL_PROFILE = gql`
  mutation approveKolProfile(
    $kolProfileId: uuid!
    $isApproved: Boolean
    $isActive: Boolean
  ) {
    update_kol_profile_by_pk(
      pk_columns: { id: $kolProfileId }
      _set: { is_active: $isActive, is_approved: $isApproved }
    ) {
      id
      is_active
      is_approved
    }
  }
`;

export const GET_KOL_USER_ID_BY_KOL_PROFILE_ID = gql`
query GetKOL($id: uuid!) {
  
  kol_profile_by_pk(id: $id) {
    user_id
  }
}
`


export const GET_KOL_USER_BY_ID = gql`
  query GetUser($id: uuid!) {
    user_by_pk(id: $id) {
      email
      authUserId
    }
  }
`;

export const GET_KOL_AVERAGE_RATING = gql`
query GetKolRating($ids: [uuid!]) {
  kol_ratings_stats (where: {kol_profile_id: {_in: $ids}}) {
    average_rating
    kol_profile_id
  }
}
`;

export const GET_KOL_RATING_FULL_INFORMATION = gql`
query GetKolRatingFullInformation($id: uuid!) {
  kol_profile(where: {id: {_eq: $id}}) {
    first_name
    last_name
    kol_file_metadata {
      file_metadata {
        url
      }
    }
  }
  kol_ratings_stats(where: {kol_profile_id: {_eq: $id}}) {
    kol_profile_id
    average_rating
    total_ratings
    content_performance
    punctuality
    content_quality_and_creativity
    professionalism_and_responsiveness
  }
  kol_ratings(where: {kol_profile_id: {_eq: $id}}) {
    content_performance
    punctuality
    content_quality_and_creativity
    professionalism_and_responsiveness
    comment
    client_profile {
      company_name
    }
    campaign {
      name
    }
  }  
}`;