import { List, Datagrid, FunctionField, SearchInput, TextField, useRecordContext, Button, useRefresh, useListContext, useGetList, useDataProvider } from "react-admin"
import { DatagridActionsColumn } from "../shared/components/DatagridActionsColumn"
import { RowButton } from "../shared/components/RowButton"
import { centsToUsd } from "../utils/format"
import * as Dialog from "@radix-ui/react-dialog"
import styled from "styled-components"
import { Flex, RadioGroup, Text } from "@radix-ui/themes"
import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { INSERT_TRANSACTION } from "../queries/transaction"
import { useAuth0 } from "@auth0/auth0-react"
import { ENABLE_DISABLE_CLIENT_PROFILE } from "../queries/client-profile"
import { Container, Button as MuiButton } from "@mui/material"
import EditBalancePopup from "./EditBalancePopup"

type HandleSaveFn = (props: { merchant_wallet_id?: string }) => void

type EditBalanceProps = {
  editProfileOpt: "AddAmount" | "ReduceAmount"
  setEditProfileOpt: (value: "AddAmount" | "ReduceAmount") => void
  setAmount: (value: number) => void
  handleAmountChange: (e: any) => void
  handleSave: HandleSaveFn
  closeDialogRef?: React.MutableRefObject<HTMLButtonElement | null>
}

const EditBalance = (props: EditBalanceProps) => {
  const record = useRecordContext()

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button label="EDIT" variant="contained" />
        {/* <RowButton label="Edit" /> */}
      </Dialog.Trigger>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <DialogTitle>Edit Balance</DialogTitle>
          <p>Current Balance</p>
          <p>{centsToUsd(record?.wallet?.amount_usd_cents)}</p>
          <RadioGroup.Root
            defaultValue="add"
            value={props.editProfileOpt}
            onValueChange={(value) => {
              props.setEditProfileOpt(value as any)
              props.setAmount(0)
            }}
          >
            <Flex gap="2" direction="column">
              <Text as="label" size="2">
                <Flex gap="2" direction={"column"}>
                  <div>
                    <RadioGroup.Item value="AddAmount" /> Add amount
                  </div>
                  {props.editProfileOpt === "AddAmount" && (
                    <div>
                      <input type="number" onChange={props.handleAmountChange} />
                    </div>
                  )}
                </Flex>
              </Text>
              <Text as="label" size="2">
                <Flex gap="2" direction={"column"}>
                  <div>
                    <RadioGroup.Item value="ReduceAmount" /> Reduce Amount
                  </div>
                  {props.editProfileOpt === "ReduceAmount" && (
                    <div>
                      <input type="number" onChange={props.handleAmountChange} />
                    </div>
                  )}
                </Flex>
              </Text>
            </Flex>
          </RadioGroup.Root>
          <div
            style={{
              display: "flex",
              marginTop: 25,
              justifyContent: "flex-end",
            }}
          >
            <Dialog.Close asChild ref={props.closeDialogRef}>
              <button className="Button">Cancel</button>
            </Dialog.Close>
            <button
              className="Button green"
              onClick={() =>
                props.handleSave({
                  merchant_wallet_id: record?.wallet?.id as any,
                })
              }
            >
              Save
            </button>
          </div>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const merchantFilters = [
  // https://github.com/hasura/ra-data-hasura#nested-filtering
  <SearchInput source="company_name@_ilike" alwaysOn />,
]

export const MerchantsList = (props: any) => {
  const refresh = useRefresh()
  const [editProfile, setEditProfile] = useState<any>(undefined)
  const [enableDisableClientProfile, enableDisableClientProfileResult] = useMutation<
    any,
    {
      isApproved: boolean
      isActive: boolean
      clientProfileId: string
    }
  >(ENABLE_DISABLE_CLIENT_PROFILE)
  const dataProvider = useDataProvider();
  const [version, setVersion] = useState(0);

  if (enableDisableClientProfileResult.called && !enableDisableClientProfileResult.loading) {
    refresh()
  }

  const roundNumberOrDash = (num: number | undefined) => {
    if (num === undefined || num == 0) {
      return "-"
    }
    return Math.round(num * 10) / 10
  }

  return (
    <Container maxWidth={false} sx={{ width: "100%", maxWidth: "none", padding: "0px", margin: "0px", paddingTop: "24px" }}>
      <List key={version} {...props} sort={{ field: "created_at", order: "DESC" }} filters={merchantFilters} queryOptions={{ fetchPolicy: 'network-only' }}>
        <Datagrid>
          <TextField label="Merchant Name" source="company_name" />
          <FunctionField label="Current Balance" render={(record: any) => centsToUsd(record?.wallet?.amount_usd_cents)} />
          <FunctionField
            label="Profile Status"
            render={(record: any) => {
              const isGlobalActive = record.is_approved && record.is_active
              const text = isGlobalActive ? "Active" : "Inactive"
              const color = isGlobalActive ? "#00AD31" : "#E4A400"

              return <span style={{ color }}>{text}</span>
            }}
          />
          <FunctionField
            label="Avg. Rating"
            render={(record: any) => {
              let rating = record?.average_rating
              return <span>{roundNumberOrDash(rating)}</span>
            }}
          />
          <DatagridActionsColumn label="Actions">
            <RowButton label="View" />
          </DatagridActionsColumn>
          <FunctionField
            label="Edit Balance"
            render={(record: any) => {
              return (
                <MuiButton variant="contained" onClick={() => setEditProfile(record)}>
                  Edit
                </MuiButton>
              )
            }}
          />
          <FunctionField
            label="Activation"
            render={(record: any) => {
              // Ideally should be 2 separate buttons
              // 1. Approve (used when kol/merchant first registers)
              // 2. Enable/Disable (used when kol/merchant is already approved)

              // Button in current design is kinda combining both
              // Logic now is:
              // If kol is approved and active, it means it's globally active
              // If it's not globally active, it means it's either not approved or not active
              const isGlobalActive = record.is_approved && record.is_active

              // BE design
              // is_approved: default false (once approved, it's true and can't be changed, is_active then is used to control enable/disable)
              // is_active: default true
              // So after registration, kol is not approved and is active
              // After approval, kol is approved and is active (globally active)
              const clientProfileId = record.id

              return (
                <DatagridActionsColumn label="Activation">
                  <RowButton
                    label={isGlobalActive ? "DISABLE" : "ENABLE"}
                    variant={"contained"}
                    color={isGlobalActive ? "error" : "success"}
                    onClick={async (e) => {
                      // Default behavior is to redirect to detail page, so we need to prevent that
                      e.preventDefault()
                      if (isGlobalActive) {
                        // update is_active to false
                        console.info(`Disabling clientProfileId: ${clientProfileId}`)
                        enableDisableClientProfile({
                          variables: {
                            clientProfileId: clientProfileId,
                            isApproved: true,
                            isActive: false,
                          },
                        })
                        return
                      }

                      if (!record.is_approved) {
                        // update is_approved to true
                        console.info(`Approving clientProfileId: ${clientProfileId}`)
                        enableDisableClientProfile({
                          variables: {
                            clientProfileId: clientProfileId,
                            isApproved: true,
                            isActive: true,
                          },
                        })
                        return
                      }

                      // update is_active to true
                      console.info(`Enabling clientProfileId: ${clientProfileId}`)
                      enableDisableClientProfile({
                        variables: {
                          clientProfileId: clientProfileId,
                          isApproved: true,
                          isActive: true,
                        },
                      })
                    }}
                  />
                </DatagridActionsColumn>
              )
            }}
          />
        </Datagrid>
      </List>
      {editProfile !== undefined && (
        <EditBalancePopup
          open={editProfile !== undefined}
          profile={editProfile}
          handleEdited={async () => {
            setTimeout(() => {
              setVersion((prev) => prev + 1);
              refresh();
            }, 500);
            setEditProfile(undefined)
          }}
          handleClose={() => setEditProfile(undefined)}
          isMerchant={true}
        />
      )}
    </Container>
  )
}

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: 1;
`

const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  z-index: 2;
`

const DialogTitle = styled(Dialog.Title)`
  font-weight: 400;
`
